<template>
  <div>
    <el-table ref="mytable" :data="list">
      <el-table-column
        label="时间"
        prop="createTime"
        width="160"
      ></el-table-column>
      <el-table-column label="图片" width="150">
        <template slot-scope="image">
          <img style="width: 100px" class="" :src="image.row.image" alt="" />
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title" width="220"></el-table-column>
      <el-table-column label="简介" prop="desc"></el-table-column>
      <el-table-column label="类别" prop="projectName" width="150"></el-table-column>
      <el-table-column label="排序" prop="sort"  width="100"></el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="item">
          <el-button @click="clickEditProjects(item.row)">编辑</el-button>
          <el-button type="danger" @click="clickRemove(item.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getProjectsList, deleteProject } from "@/apis/projects";

export default {
  components: {},
  props: {},
  data() {
    return {
      list: [],
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.getProjectsList();
  },
  methods: {
    // *案例列表
    getProjectsList() {
      getProjectsList({}).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },

    // *编辑案例
    clickEditProjects(item) {
      this.$router.push({
        path: "/index/projectAdd",
        query: {
          id: item.id,
        },
      });
    },

    //*删除案例
    clickRemove(val) {
      this.$confirm("确定删除该案例吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteProject({ id: val.id }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getProjectsList();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
