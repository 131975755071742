import axios from "../utils/request";
// import axios from "../utils/utils";

// *案例管理
export let getProjectsList = (params) => axios.post("project/getProjects",params);

// *添加案例
export let addProject = (params) => axios.post("project/addProject", params);

// *更新案例
export let updateProject = (params) => axios.post("project/updateProject", params);

// *删除案例
export let deleteProject = (params) => axios.post("project/removeProject", params);

//*通过id获取案例详情
export let getProjectById = (params) => axios.post("project/getById", params);